import React, {Component} from "react"
 
class Articles extends Component {
  state = {

  }

  render() {
    return (
    <div>
      <h1>Articles</h1>

      <p>I am hoping to fill this tab with articles I find interesting. This page will probably remain empty for the immediate future. Further down the road I will fill it with work related Projects and Articles.</p>
    </div>
    )

  }
}
 
export default Articles;